<template>
  <div>
    <div class="flex">
      <div>
        <br />
        <table class="reciept-table">
          <thead>
            <tr>
              <th>Настройка</th>
              <th>Значение</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Токен Telegram-бота родителей</td>
              <td>
                <input
                  type="text"
                  id="token"
                  style="width: 400px"
                  class="form-control"
                  required
                  v-model="tokenTelegramBot"
                />
              </td>
              <td>
                <button
                  v-if="!checkCreateToken"
                  @click="createTokenTelegramBot()"
                  id="createReciept"
                  class="btn btn-primary"
                  style="padding: 0 0 0 0; margin: 0 0 0 0"
                >
                  Создать токен
                </button>
                <button
                  v-if="checkCreateToken"
                  @click="updateTokenTelegramBot()"
                  id="createReciept"
                  class="btn btn-primary"
                  style="padding: 0 0 0 0; margin: 0 0 0 0"
                >
                  Обновить токен
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";
export default {
  data() {
    return {
      tokenTelegramBot: "",
      checkCreateToken: false,
    };
  },
  methods: {
    async createTokenTelegramBot() {
      let data = { token: this.tokenTelegramBot };
      await TutorialDataService.createTelegramToken(data)
        .then((res) => {
          console.log(res);
          alert("Успешно");
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },
    async updateTokenTelegramBot() {
      let data = { token: this.tokenTelegramBot };
      await TutorialDataService.updateTelegramToken(data)
        .then((res) => {
          console.log(res);
          alert("Успешно");
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },
    async getTokenTelegramBot() {
      await TutorialDataService.getTelegramToken()
        .then((res) => {
          this.tokenTelegramBot = res.data;
          console.log(res.data);
          if (String(res.data).length > 0) {
            this.checkCreateToken = true;
          }
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },
  },
  async mounted() {
    await this.getTokenTelegramBot();
  },
};
</script>

<style></style>
